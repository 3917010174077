<template>
  <div id="complain-management-detail" v-loading="isLoading">
    <el-form 
      ref="form" 
      :model="detail" 
      label-position="top" 
      label-width="120px"
      disabled
    >

      <el-row type="flex" justify="end" align="middle" class="status-row">
        <span class="el-form-item__label text-bold">{{ $t('STATUS') }}:</span>
        <el-select 
          v-model="detail.status"
          :class="getStatusClass(detail.status) + (isMobile ? ' w-100': '')"
        >
          <el-option
            v-for="item in allSelectableStatusListFor(detail.status)"
            :key="item.value"
            :label="$t(item.value)"
            :value="item.value"
          />
        </el-select>
      </el-row>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">

        <el-col>
          <el-form-item :label="$t('TIME')" prop="id">
            <el-input :value="parseDate(detail.createdDate)" />
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item :label="$t('TYPE')">
            <el-input :value="type" />
          </el-form-item>
        </el-col>

        <el-col :span="isMobile ? 24 : 8">
          <el-form-item :label="$t('DOCUMENT_LINK')">
            <div class="el-input el-input--medium is-disabled">
              <div class="el-input__inner">
                <el-link 
                  type="primary"
                  @click="goToDocumentLink()"
                >
                  {{ $t("CLICK_TO_OPEN") }}
                </el-link>
                <i 
                  class="custom-icon mini pointer icon-copy"
                  @click="copyLink()"
                />
              </div>
            </div>
          </el-form-item>
        </el-col>

      </el-row>

      <el-row type="flex" justify="center" align="middle">
        <el-form-item class="w-100" :label="$t('COMPLAINT_TITLE')">
          <el-input :value="detail.subject">
            <template slot="prefix">
              <i class="custom-icon icon-article mini"/>
            </template>
          </el-input>
        </el-form-item>
      </el-row>

      <el-row type="flex" justify="center" align="middle">
        <el-form-item class="w-100" :label="$t('COMPLAINT')">
          <el-input type="textarea" resize="none" :rows="4" :value="detail.content"/>
        </el-form-item>
      </el-row>

      <h1>{{ $t("COMPLAINT_FROM") }}</h1>
      <el-row v-if="detail.user" :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('FIRST_NAME')" class="w-100">
            <el-input :value="detail.user.firstName" disabled>
              <template slot="prefix">
                <i class="custom-icon icon-name-placeholder-prefix mini"/>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('EMAIL')" class="w-100">
            <el-input :value="detail.user.email" disabled>
              <template slot="prefix">
                <i class="custom-icon icon-email-placeholder-prefix mini"/>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('PHONE')" class="w-100">
            <el-input :value="detail.user.phone" disabled>
              <template slot="prefix">
                <i class="custom-icon icon-phone-placeholder-prefix mini"/>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>


      <page-footer
        :isLoading="isLoading"
        :handleBackTo="exit"
      />

    </el-form>

  </div>
</template>


<script>
import { generalMixin } from '@/utils/general-mixin'
import PageFooter from "@/components/page-footer.vue"
import { getComplainById } from "@/api/complain"
import { fallbackCopyTextToClipboard } from "@/utils/helpers.js";

export default {
  name: 'ComplainManagementDetail',
  components: { PageFooter },
  mixins: [ generalMixin ],
  data() {
    return {
      isLoading: false,
      detail: {
        // no need define, display only dont need to be reactive
      }
    }
  },
  computed: {
    type() {
      let code = ""
      if (this.detail.advertisement != null) { 
        code = "ADVERTISEMENT"
      } else if (this.detail.article != null) {
        code = "ARTICLE" 
      } else if (this.detail.p2pTransaction != null) {
        code = "P2P_TRANSACTION"
      }

      return this.$t(code)
    }
  },
  methods: {
    // REDIRECTION
    exit() { this.goTo('/bo/complain') },
    getDetail() {
      this.isLoading = true
      getComplainById(this.id).then(res => {
        if (res.status == "ok") {
          this.detail = res.result
        }
      })
      .finally(() => this.isLoading = false)
    },
    goToDocumentLink() {
      if (this.detail.id == null) return

      let path
      if (this.detail.advertisement != null) {
        path = `/bo/advertisement/detail/0/${this.detail.advertisement.id}/0`
      } else if (this.detail.article != null) {
        path = `/${this.getMetaTitle(this.detail.article)}`
      } else if (this.detail.p2pTransaction != null) {
        path = `/bo/p2p-market/transaction/${this.detail.p2pTransaction.transactionRef}`
      }
      this.goTo(path, true)
    },
    copyLink() {
      if (this.detail.id == null) return

      let path
      if (this.detail.advertisement != null) {
        path = `/bo/advertisement/detail/0/${this.detail.advertisement.id}/0`
      } else if (this.detail.article != null) {
        path = `/${this.getMetaTitle(this.detail.article)}`
      } else if (this.detail.p2pTransaction != null) {
        path = `/bo/p2p-market/transaction/${this.detail.p2pTransaction.transactionRef}`
      }
      const route = this.$router.resolve(path);
      const link = new URL(route.href, window.location.origin).href;
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(link)
      } else {
        navigator.clipboard.writeText(link)
      }
      this.$notify({
        message: this.$t("COPY_TO_CLIPBOARD"),
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      })
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getDetail()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";

#complain-management-detail {
  background-color: $white;
  border-radius: 10px;
  padding-bottom: 2rem;
  padding-top: 2rem;
  .status-row {
    >span {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .el-form {
    margin: auto $spacingLeftRight;
  }
  .icon-copy {
    margin-left: 2rem
  }
  .el-link {
    margin-bottom: 10px
  }
}

.mobile #complain-management-detail {
  .icon-copy {
    float: right;
    margin-top: 0.5rem;
  }
}
</style>